<!-- Footer start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <div>
          <h5 class="mb-4 footer-list-title">ContrALL</h5>
          <p>Plataforma de gestión de capacidad dentro de eventos cerrados </p>
        </div>
      </div>
      <div class="col-lg-2 offset-lg-1 col-sm-6"></div>
      <div class="col-lg-2 col-sm-6"></div>

      <div class="col-lg-3 col-sm-12">
        <div>
          <h5 class="mb-4 footer-list-title contact-section">Contacto</h5>
          <div>
            <!--<div class="media">
              <i-feather name="map-pin" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>476 University Drive Ridge, IL 61257</p>
              </div>
            </div>-->
            <div class="media">
              <i-feather name="mail" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>info@contrall.es</p>
              </div>
            </div>
            <div class="media">
              <i-feather name="phone" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>607 429 024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->

</footer>
<!-- Footer end -->

<!-- Footer alt start -->
<section class="bg-primary py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-sm-left">
          <a href="javascript: void(0);">
            <img src="assets/images/logo-light.png" alt="" height="20">
          </a>
        </div>
        <div class="float-sm-right mt-4 mt-sm-0">
          <p class="copyright-desc text-white mb-0">{{year}} © Camino Aplicación S.L. Todos los derechos reservados</p>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Footer alt start -->
