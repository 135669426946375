// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAPfnBM7R5D2t6sPV34Ri5GKX5j9DYTk5Q",
    authDomain: "contrall-projct.firebaseapp.com",
    projectId: "contrall-projct",
    storageBucket: "contrall-projct.appspot.com",
    messagingSenderId: "701781128267",
    appId: "1:701781128267:web:77da33e41ebf1a94e7c9ce",
    measurementId: "G-MCDLMCNJ89"
  },
  applicationName: 'com.onlinepro.mobile.contrall',
  apiLoginUrl: 'https://api.contrall.es/api/auth/login',
  apiCheckUrl: 'https://api.contrall.es/api/auth/check',
  apiUsuarioGetUrl: 'https://api.contrall.es/api/auth/usuarios/',
  apiUsuarioUpdateUrl: 'https://api.contrall.es/api/auth/usuarios/update/',
  apiLogoutUrl: 'https://api.contrall.es/api/auth/logout',
  apiRegisterUrl: 'https://api.contrall.es/api/auth/usuarios/registro',
  apiRecoverUrl: 'https://api.contrall.es/api/auth/resetPassword',
  apiConfirmUrl: 'https://api.contrall.es/api/auth/confirmReset',
  apiEventosListUrl: 'https://api.contrall.es/api/v1/evento/listarEventosVisibles',
  apiEventosUsuarioListUrl: 'https://api.contrall.es/api/v1/evento/inscripcionesUsuario',
  apiEventoUrl: 'https://api.contrall.es/api/v1/evento/',
  apiEventoInscripcionUrl: 'https://api.contrall.es/api/v1/evento/inscripcion/',
  apiEventoBajaUrl: 'https://api.contrall.es/api/v1/evento/inscripcion/baja/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
